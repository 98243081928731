var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gnb_pdt",attrs:{"id":"container"}},[_vm._m(0),_c('common-area-search',{attrs:{"row":2,"colspan":2},on:{"search":_vm.allApiCall,"clear":_vm.cleanSearchDataEvent},scopedSlots:_vm._u([{key:"R1_C1",fn:function(){return [_c('label',[_vm._v("회사명")]),_c('ul',[_c('common-multi-drop-down',{ref:"cmpyCdRef",attrs:{"params":{ 'sort[0].dir': 'asc', 'sort[0].field': 'cmpyNm', searchCmpyGbnGrup : [_vm.Constant.efs.cmpyGbn.CLIENT_COMPANY] },"default-index":0,"data-columns":[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }],"url":((_vm.ApiConfig.efsDomain) + "/efs/drop-down/company"),"data-value-field":'cmpyCd',"data-text-field":'cmpyNm'},on:{"change":function (value){
                _vm.propsCmpyCd = value
                _vm.$refs.locaCdRef.widget().value('')
                _vm.allApiCall()
              }}})],1)]},proxy:true},{key:"R1_C2",fn:function(){return [_c('label',[_vm._v("조회일")]),_c('ul',[_c('kendo-datepicker',{ref:"dtRef",attrs:{"value":new Date(),"disabled":true,"format":'yyyy/MM/dd',"placeholder":'년/월/일'},on:{"change":_vm.allApiCall}})],1)]},proxy:true},{key:"R2_C1",fn:function(){return [_c('label',[_vm._v("로케이션")]),_c('ul',[_c('common-multi-drop-down',{ref:"locaCdRef",attrs:{"params":{ searchCmpyCd : _vm.propsCmpyCd },"all-params-validator":true,"url":((_vm.ApiConfig.efsDomain) + "/efs/drop-down/location"),"data-value-field":'locaCd',"data-text-field":'locaNm'},on:{"change":_vm.allApiCall}})],1)]},proxy:true}])}),_c('div',{staticClass:"box_flex"},[_c('div',{staticClass:"contents_wrap mgt10"},[_vm._m(1),_c('kendo-chart',{attrs:{"render-as":'canvas',"chart-area":{background : '', height:270, margin: { top : 10, left : 0, right :0, bottom:10 }},"legend-item-click":_vm.onClickLegend,"pannable-lock":'y',"transitions":_vm.inTransitions,"series":[
                        {
                          type: _vm.inRetChartData.series.type,
                          data: _vm.inRetChartData.series.data,
                          axis: _vm.inRetChartData.series.axis,
                          visible : _vm.inRetChartData.series.visible,
                          categoryField: _vm.inRetChartData.series.categoryField,
                          name: _vm.constant.inRet,
                          field : _vm.inRetChartData.series.field,
                          color: _vm.inRetChartData.series.color,
                          tooltip : _vm.inRetChartData.series.tooltip
                        },
                        {
                          type: _vm.inNewChartData.series.type,
                          data: _vm.inNewChartData.series.data,
                          axis: _vm.inNewChartData.series.axis,
                          visible : _vm.inNewChartData.series.visible,
                          categoryField: _vm.inNewChartData.series.categoryField,
                          name: _vm.constant.inNew,
                          field : _vm.inNewChartData.series.field,
                          color: _vm.inNewChartData.series.color,
                          tooltip : _vm.inNewChartData.series.tooltip
                        }],"category-axis":{
                        justified: true,
                        axisCrossingValues: [0, 999, 0],
                      },"value-axis":[
                        _vm.inRetChartData.valueAxis,
                        _vm.inNewChartData.valueAxis
                      ],"legend":{
                        visible : true,
                        position : 'top',
                      },"theme":'sass'}})],1),_c('div',{staticClass:"contents_wrap mgt10"},[_vm._m(2),_c('div',{staticClass:"box_flex w100"},[_c('kendo-chart',{attrs:{"render-as":'canvas',"chart-area":{background : '', height:270, margin: { top : 10, left : 0, right :0, bottom:10 }},"series":[{
                          type: _vm.outChartData.series.type,
                          data: _vm.outChartData.series.data,
                          axis: _vm.outChartData.series.axis,
                          visible : _vm.outChartData.series.visible,
                          categoryField: _vm.outChartData.series.categoryField,
                          field : _vm.outChartData.series.field,
                          color: _vm.outChartData.series.color,
                          tooltip : _vm.outChartData.series.tooltip
                        }],"category-axis":{
                        justified: true,
                      },"value-axis":[_vm.outChartData.valueAxis],"legend-visible":false,"theme":'sass'}})],1)]),_c('div',{staticClass:"contents_wrap mgt10"},[_vm._m(3),_c('div',{staticClass:"box_flex w100"},[_c('div',[_c('kendo-chart',{attrs:{"render-as":'canvas',"chart-area":{background : '', height:270, margin: { top : 10, left : 0, right :0, bottom:10 }},"series":[{
                          type: _vm.stkChartData.series.type,
                          data: _vm.stkChartData.series.data,
                          axis: _vm.stkChartData.series.axis,
                          visible : _vm.stkChartData.series.visible,
                          categoryField: _vm.stkChartData.series.categoryField,
                          field : _vm.stkChartData.series.field,
                          color: _vm.stkChartData.series.color,
                          tooltip : _vm.stkChartData.series.tooltip
                        }],"category-axis":{
                        justified: true,
                      },"value-axis":[_vm.stkChartData.valueAxis],"legend-visible":false,"theme":'sass'}})],1)])])]),_c('div',{staticClass:"box_flex mgt10"},[_c('div',{staticClass:"contents_wrap w20"},[_vm._m(4),_c('div',{staticClass:"box_graph",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"inner-content"},[(_vm.goodsChartData.totalCount > 0)?_c('span',{staticClass:"s_txt",staticStyle:{"top":"30%"}},[_vm._v("총 재고")]):_vm._e(),(_vm.goodsChartData.totalCount > 0)?_c('span',{staticClass:"txt_per",staticStyle:{"top":"35%"}},[_vm._v(_vm._s(_vm.goodsChartData.totalCount)+"EA")]):_vm._e()]),_c('kendo-chart',{class:'item_graph',attrs:{"data-source":_vm.goodsChartData.itemPercentInfoList,"series":[{ type: 'donut', field : 'cnt', holeSize: 70,}],"tooltip":{ visible : _vm.goodsChartData.totalCount !== 0, template : "#= dataItem.itemNm # : #= kendo.format('{0:N0}', dataItem.cnt) #EA&nbsp" },"chart-area":{background : '', height:300, margin: { top : -10, left : -10, right :-10, bottom:-10 }},"legend-visible":false,"theme":'sass'}})],1)]),_c('div',{staticClass:"contents_wrap w30 mg0"},[_vm._m(5),_c('div',[_c('div',[_c('kendo-chart',{attrs:{"render-as":'canvas',"chart-area":{background : '', height:330, margin: { top : 10, left : 10, right :20, bottom:10 }},"pannable-lock":'y',"series":[{
                          type: _vm.outRankChartData.series.type,
                          data: _vm.outRankChartData.series.data,
                          axis: _vm.outRankChartData.series.axis,
                          visible : _vm.outRankChartData.series.visible,
                          categoryField: _vm.outRankChartData.series.categoryField,
                          field : _vm.outRankChartData.series.field,
                          color: _vm.outRankChartData.series.color,
                          tooltip : _vm.outRankChartData.series.tooltip
                        }],"category-axis":{justified: true},"value-axis":[_vm.outRankChartData.valueAxis],"legend":{visible:true},"theme":'sass'}})],1)])]),_c('div',{staticClass:"contents_wrap w50 mgl10"},[_c('div',{staticClass:"small_title_wrap"},[_c('h2',[_vm._v(" 상품 재고현황 "),_c('button',{staticClass:"mid_btn orange",on:{"click":function($event){_vm.$refs.stkItemListWindowRef.kendoWidget().center().open()}}},[_vm._v("일별 수불현황")])])]),_c('kendo-grid',{staticClass:"mgt20 w50 f_r",attrs:{"height":330,"sortable-mode":'multiple',"navigatable":false,"reorderable":true,"column-menu":false,"resizable":true,"selectable":false,"data-source":_vm.renewStkStatDataSource,"columns":_vm.renewGridColumns,"noRecords":{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}}}),_c('kendo-grid',{staticClass:"mgt20 w50 f_r",attrs:{"height":330,"sortable-mode":'multiple',"navigatable":false,"reorderable":true,"column-menu":false,"resizable":true,"selectable":false,"data-source":_vm.newStkStatDataSource,"columns":_vm.newGridColumns,"noRecords":{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}}})],1)]),_c('kendo-window',{ref:"stkItemListWindowRef",staticStyle:{"display":"none","height":"720px","min-width":"1500px"},attrs:{"title":'일별 수불현황',"modal":true,"width":1700},on:{"open":function (e){ _vm.isWindowOpen = true },"close":function (e){ _vm.isWindowOpen = false }}},[_c(_vm.isWindowOpen ? 'StkLocationList' : null,{tag:"component",attrs:{"default-cmpy-cd":_vm.propsCmpyCd}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title_wrap"},[_c('h1',[_vm._v("로케이션별통계")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap w100"},[_c('h2',[_vm._v("입고수")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap w100"},[_c('h2',[_vm._v("출고수")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap w100"},[_c('h2',[_vm._v("재고변동")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap"},[_c('h2',[_vm._v("품목 비율")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap"},[_c('h2',[_vm._v("출고 지역 순위 TOP5")])])}]

export { render, staticRenderFns }